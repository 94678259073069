<script>
    import { Doughnut } from 'vue-chartjs'

    export default {
        extends: Doughnut,
        name: "ProfessionalExperienceChart",
        data: () => ({
            chartdata: {
                datasets: [
                    {
                        data: [22, 11, 44, 22],
                        backgroundColor: ['#5bc0de', '#5cb85c', '#f0ad4e', '#d9534f']
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                events: []
            }
        }),

        mounted () {
            this.renderChart(this.chartdata, this.options)
        }
    }
</script>
