<template>
  <div class="about">
    <section class="header-image">
      <div class="header-image-overlay"></div>
      <b-container>
        <b-row>
          <b-col lg="7"><h1>{{ $t('message.about_us.title') }}</h1>
            <p class="lead">
              {{ $t('message.about_us.subtitle') }}
            </p>
          </b-col>
          <b-col md="5">
            <div class="header-breadcrumb">
              <router-link class="link-alt" :to="{ name: $i18n.locale + '_home' }">{{ $t('message.home.title') }}</router-link>
              • <span class="text-muted">{{ $t('message.about_us.title') }}</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="nav-anchors">
      <b-nav class="justify-content-center">
        <b-nav-item href="#about-us" v-scroll-to="'#about-us'">{{ $t('message.about_us.nav.our_company') }}</b-nav-item>
        <b-nav-item href="#team" v-scroll-to="'#team'">{{ $t('message.about_us.nav.our_team') }}</b-nav-item>
        <b-nav-item href="#join-us" v-scroll-to="'#join-us'">{{ $t('message.about_us.nav.join_us') }}</b-nav-item>
      </b-nav>
    </section>

    <section class="section bg-black-syntelix text-white" id="about-us">
      <b-container>
        <h2 class="text-center text-orange-syntelix">{{ $t('message.about_us.our_company.title') }}</h2>
        <hr class="hr-gradient-orange">
        <p>
          {{ $t('message.about_us.our_company.paragraph_1') }}
        </p>
        <p>
          {{ $t('message.about_us.our_company.paragraph_2') }}
        </p>
      </b-container>
    </section>

    <section class="section">
      <b-container>
        <b-row class="text-center">
          <b-col sm="4">
            <span class="icon-circle text-muted"><span class="li-telescope"></span></span>
            <h3 class="icon-circle-caption">{{ $t('message.about_us.our_company.vision_statement.title') }}</h3>
            <ul class="list-unstyled">
              <li>
                {{ $t('message.about_us.our_company.vision_statement.paragraph_1') }}
                <hr class="hr-gradient">
              </li>
              <li>
                {{ $t('message.about_us.our_company.vision_statement.paragraph_2') }}
                <hr class="hr-gradient">
              </li>
              <li>
                {{ $t('message.about_us.our_company.vision_statement.paragraph_3') }}
                <hr class="hr-gradient">
              </li>
            </ul>
          </b-col>
          <b-col sm="4">
            <span class="icon-circle text-muted"><span class="li-linegraph"></span></span>
            <h3 class="icon-circle-caption">{{ $t('message.about_us.our_company.mission_statement.title') }}</h3>
            <ul class="list-unstyled">
              <li>
                {{ $t('message.about_us.our_company.mission_statement.paragraph_1') }}
                <hr class="hr-gradient">
              </li>
              <li>
                {{ $t('message.about_us.our_company.mission_statement.paragraph_2') }}
                <hr class="hr-gradient">
              </li>
              <li>
                {{ $t('message.about_us.our_company.mission_statement.paragraph_3') }}
                <hr class="hr-gradient">
              </li>
            </ul>
          </b-col>
          <b-col sm="4"><span class="icon-circle text-muted"><span class="li-heart"></span></span>
            <h3 class="icon-circle-caption">{{ $t('message.about_us.our_company.values_statement.title') }}</h3>
            <ul class="list-unstyled">
              <li>
                {{ $t('message.about_us.our_company.values_statement.paragraph_1') }}
                <hr class="hr-gradient">
              </li>
              <li>
                {{ $t('message.about_us.our_company.values_statement.paragraph_2') }}
                <hr class="hr-gradient">
              </li>
              <li>
                {{ $t('message.about_us.our_company.values_statement.paragraph_3') }}
                <hr class="hr-gradient">
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="section bg-gray-alt" id="team">
      <b-container>
        <b-row>
          <b-col xs="12" lg="7">
            <div class="heading-title heading-border-bottom"><h3>{{ $t('message.about_us.our_team.title') }}</h3></div>
            <p>
              {{ $t('message.about_us.our_team.paragraph_1') }}
            </p>
            <p>
              {{ $t('message.about_us.our_team.paragraph_2') }}
            </p>
            <div class="heading-title heading-border-bottom heading-margin-top">
              <h4>{{ $t('message.about_us.our_team.specialization.title') }}</h4>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.specialization.bar_1') }}</p>
              <b-progress :max="31" class="mb-3" variant="info">
                <b-progress-bar :value="27" label="27%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.specialization.bar_2') }}</p>
              <b-progress :max="31" class="mb-3" variant="success">
                <b-progress-bar :value="31" label="31%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.specialization.bar_3') }}</p>
              <b-progress :max="31" class="mb-3" variant="warning">
                <b-progress-bar :value="12" label="12%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.specialization.bar_4') }}</p>
              <b-progress :max="31" class="mb-3" variant="danger">
                <b-progress-bar :value="15" label="15%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.specialization.bar_5') }}</p>
              <b-progress :max="31" class="mb-3" variant="primary">
                <b-progress-bar :value="22" label="22%"></b-progress-bar>
              </b-progress>
            </div>

            <div class="heading-title heading-border-bottom heading-margin-top"><h4>{{ $t('message.about_us.our_team.our_magnitudes.title') }}</h4></div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.our_magnitudes.bar_1') }}</p>
              <b-progress :max="30" class="mb-3" variant="info">
                <b-progress-bar :value="22" label="22%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.our_magnitudes.bar_2') }}</p>
              <b-progress :max="30" class="mb-3" variant="success">
                <b-progress-bar :value="30" label="30%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.our_magnitudes.bar_3') }}</p>
              <b-progress :max="30" class="mb-3" variant="warning">
                <b-progress-bar :value="29" label="29%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.our_magnitudes.bar_4') }}</p>
              <b-progress :max="30" class="mb-3" variant="danger">
                <b-progress-bar :value="19" label="19%"></b-progress-bar>
              </b-progress>
            </div>

          </b-col>
          <b-col xs="12" lg="5">
            <div class="heading-title heading-border-bottom heading-experience"><h3>{{ $t('message.about_us.our_team.professional_experience.title') }}</h3></div>
            <div class="d-flex justify-content-center">
              <div style="max-width: 287px;">
                <ProfessionalExperienceChart></ProfessionalExperienceChart>
              </div>
            </div>

            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.professional_experience.bar_1') }}</p>
              <b-progress :max="44" class="mb-3" variant="info">
                <b-progress-bar :value="22" label="22%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.professional_experience.bar_2') }}</p>
              <b-progress :max="44" class="mb-3" variant="success">
                <b-progress-bar :value="11" label="11%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.professional_experience.bar_3') }}</p>
              <b-progress :max="44" class="mb-3" variant="warning">
                <b-progress-bar :value="44" label="44%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.professional_experience.bar_4') }}</p>
              <b-progress :max="44" class="mb-3" variant="danger">
                <b-progress-bar :value="22" label="22%"></b-progress-bar>
              </b-progress>
            </div>


            <div class="heading-title heading-border-bottom heading-margin-top"><h3>{{ $t('message.about_us.our_team.languages.title') }}</h3></div>

            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.languages.bar_1') }}</p>
              <b-progress :max="56" class="mb-3" variant="info">
                <b-progress-bar :value="56" label="56%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.languages.bar_2') }}</p>
              <b-progress :max="56" class="mb-3" variant="success">
                <b-progress-bar :value="33" label="33%"></b-progress-bar>
              </b-progress>
            </div>
            <div class="mb-4">
              <p>{{ $t('message.about_us.our_team.languages.bar_3') }}</p>
              <b-progress :max="56" class="mb-3" variant="warning">
                <b-progress-bar :value="11" label="11%"></b-progress-bar>
              </b-progress>
            </div>

          </b-col>
        </b-row>
      </b-container>
    </section>

    <section class="section" id="join-us">
      <b-container>
        <h2 class="text-center">{{ $t('message.about_us.work_with_us.title') }}</h2>
        <hr class="hr-gradient">
        <p>{{ $t('message.about_us.work_with_us.paragraph_1') }}</p>
        <p>{{ $t('message.about_us.work_with_us.paragraph_2') }}</p>
      </b-container>
    </section>

    <QuickContact />
  </div>
</template>
<script>
  import ProfessionalExperienceChart from "@/components/ProfessionalExperienceChart";
  import QuickContact from "../components/QuickContact";
  export default {
    name: 'About',
    components: { QuickContact, ProfessionalExperienceChart },
     metaInfo () {
      return  {
        htmlAttrs: {
          lang: this.$i18n.locale
        },
        title: this.$t('meta.about.title'),
        link: [
          { rel: 'canonical', href: 'https://www.syntelix.net' + this.$route.path.replace(/\/$/, "") }
        ],
        meta: [
          {
            vmid: 'description',
            name: 'description',
            content: this.$t('meta.about.description')
          },
          {
            vmid: 'og:title',
            property: 'og:title',
            content: this.$t('meta.about.title')
          },
          {
            vmid: 'og:description',
            property: 'og:description',
            content: this.$t('meta.about.description')
          },
          {
            vmid: 'og:type',
            property: 'og:type',
            content: 'website'
          },
          {
            vmid: 'og:image',
            property: 'og:image',
            content: 'https://syntelix-website.s3-eu-west-1.amazonaws.com/og.jpg'
          },
          {
            vmid: 'og:url',
            property: 'og:url',
            content: 'https://www.syntelix.net' + this.$route.path
          }
        ],
        script: [{
          type: 'application/ld+json',
          json: {
            "@context": "http://schema.org",
            "@type": "Corporation",
            "legalName": "Syntelix Avances Tecnológicos S.L.",
            "brand": "Syntelix",
            "name": "Syntelix",
            "description": this.$t('meta.home.description'),
            "url": "https://www.syntelix.net",
            "email": "info@syntelix.net",
            "faxNumber": "(+34) 986 064 336",
            "logo": 'https://www.syntelix.net' + require('@/assets/images/logo.png'),
            "telephone": "(+34) 911 938 062",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "PTL Valladares Calle C, Nave C4",
              "addressLocality": "Vigo",
              "addressCountry": "España",
              "postalCode": "36314"
            }
          }
        }]
      }
    },
  }
</script>
